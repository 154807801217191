const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    BUCKET: 'vjt-notes-app-upload',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://37ztjwvfw9.execute-api.us-west-2.amazonaws.com/dev',
    ASSESSMENTS_URL: 'https://medj5jtqwi.execute-api.us-west-2.amazonaws.com/prodtest',
    COMPANIES_URL: 'https://muo3frg0u7.execute-api.us-west-2.amazonaws.com/prodtest',
    DASHBOARDS_URL: 'https://vplemhz5j0.execute-api.us-west-2.amazonaws.com/prodtest',
    DOWNTIMES_REASONS_URL: 'https://uc1hnpf79d.execute-api.us-west-2.amazonaws.com/prodtest',
    DOWNTIMES_EVENTS_URL: 'https://wehtyrg55l.execute-api.us-west-2.amazonaws.com/prodtest',
    INSPECTIONS_URL: 'https://medj5jtqwi.execute-api.us-west-2.amazonaws.com/prodtest',
    LINES_URL: 'https://tdxy6ro3vb.execute-api.us-west-2.amazonaws.com/prodtest',
    OEE_URL: 'https://ste1miag2k.execute-api.us-west-2.amazonaws.com/prodtest',
    PRODUCTS_URL: 'https://m3onhvkg98.execute-api.us-west-2.amazonaws.com/prodtest',
    ROLES_URL: 'https://ipgxosve53.execute-api.us-west-2.amazonaws.com/prodtest',
    SCHEDULES_URL: 'https://ca9t6os5gc.execute-api.us-west-2.amazonaws.com/prodtest',
    SHIFT_URL: 'https://ylh9khlir7.execute-api.us-west-2.amazonaws.com/prodtest',
    SITES_URL: 'https://00ncia3wyd.execute-api.us-west-2.amazonaws.com/prodtest',
    USERS_URL: 'https://tb7qh8fyb0.execute-api.us-west-2.amazonaws.com/prodtest',
    NOTIFICATION_URL: 'https://v7p58oj5ic.execute-api.us-west-2.amazonaws.com/prodtest',
    CIRRUS_URL: 'https://www.cirrusprint.net',
    TEMPLATE_DESIGN_API_URL: 'https://localhost:5001',
    SALESFORCE_URL: 'https://videojet--uat.sandbox.my.salesforce.com/',
    VRS_URL: 'https://vrs-prodtest.connectvideojet.com/',
    VCCM_HELP_URL: 'https://help.videojetcloud.com/',
    API_APP_URL: 'api.prodtest.videojetcloud.com',
    NGINX_HTTP_SERVER: 'http-print.videojetcloud.com',
    NGINX_VNC_SERVER: 'vnc-print.videojetcloud.com',
    NGINX_SSH_PORT: '443',
    NGINX_COOKIE_DOMAIN: 'videojetcloud.com',
    API_BASE_URL: 'https://qqcs6vjap0.execute-api.us-west-2.amazonaws.com/prodtest'
  },
  appSync: {
    REGION: 'us-west-2',
    VRS_APPSYNC_URL: 'https://jrox5oieivdfnct4tnti7oq3qu.appsync-api.us-west-2.amazonaws.com/graphql',
    RES_APPSYNC_URL: 'https://bwu3xzqqrbcqdgrgwcf6kkdkc4.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_DEVICE_URL: 'https://jrox5oieivdfnct4tnti7oq3qu.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_REPORT_URL: 'https://gw2zbqvklbcvnb2l6fatkmd27y.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_RES_URL: 'https://6quhtkarvrekne4hpqhxkiqyeq.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_COMPANY_URL: 'https://zebndzj7jfh6vm2fgszmqjiqte.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_SITE_URL: 'https://3tbxa4gguzdqhfm6puqayh6ipy.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_SYSTEM_URL: 'https://bdmvlo7kmbhrje4pkchfbeck4i.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_USER_URL: 'https://sxpwfih2czd7lhntqkgw7em2de.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_NOTIFICATION_URL: 'https://goitdffha5b3zdezza3syoqavm.appsync-api.us-west-2.amazonaws.com/graphql',
  },

  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_0C51LAbX0',
    APP_CLIENT_ID: '2kjt2ec2vp61jfsc0jb9qqgmsg',
    IDENTITY_POOL_ID: 'us-west-2:9208c8df-f997-4480-a6b4-c8544a295c31',
    OATH_TOKEN_URL: 'https://prodtest.videojetcloud.com/oauth2',
    SSO_API: 'https://vjcloudservices.auth.us-west-2.amazoncognito.com/authorize?identity_provider=VJHUBADFS&response_type=token&client_id=3aarms5h44ejke940nobm4srso&redirect_uri=https://vccm.dev.videojetcloud.com/showJWT',
  },
  settings: {
    CLIENT_VERSION: '57d22762ac81f7f88368bd6d45bcb9d6fd67b75f',
    UM_MODULE: 'VCCM',
    MF_PROJECT: 'none',
    VRS_VERSION: '2.9.95'
  },
};

export default config;
